import React, { useState } from 'react';
import DropZoneDialog from '../DropZone/DropZoneDialog';
import { Typography, Grid, Box, CircularProgress } from '@material-ui/core';
import { useStyles } from '../../Questions/styles';
import ClearIcon from '@material-ui/icons/Cancel';

const ImageUpload = ({
	text,
	bottomText,
	onSave,
	imageFiles,
	openImageModal,
	onRemove,
	sampleImageUrl,
	sampleImageDesc,
	imageLoading,
	imageError,
	imageRemoveError,
	filesLimit,
}) => {
	const [imageLoaded, setImageLoaded] = useState(false);
	const classes = useStyles();

	const parseImageUrl = (url) => {
		return url.split('/').pop();
	};

	const retryImageLoading = (ev) => {
		ev.target.src = ev.target.src + '?t=' + new Date().getTime();
	};

	return (
		<Grid container className={classes.imageUploadContainer}>
			<Grid item xs={12} md={sampleImageUrl ? 7 : 12} className={classes.imageUploadQuestion}>
				<Grid item xs={12} sm={10}>
					<Typography component="div">
						<Box fontSize="h7.fontSize" fontWeight="800">
							<div className="block-quote-lg--primary">{text}</div>
						</Box>
					</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<DropZoneDialog
						onSave={onSave}
						files={imageFiles}
						text={text}
						bottomText={bottomText}
						filesLimit={filesLimit}
					/>
				</Grid>
				{imageError && (
					<Box mt={3}>
						<p className="paragraph-1--secondary">Error saving image</p>
					</Box>
				)}
				{imageRemoveError && (
					<Box mt={3}>
						<p className="paragraph-1--secondary">Error removing image</p>
					</Box>
				)}
				{imageLoading && (
					<Box mt={3}>
						<CircularProgress />
					</Box>
				)}
				{imageFiles.length > 0 && !imageLoading && (
					<Box mt={3}>
						<p className="paragraph-1--secondary">Image successfully uploaded: </p>
						{imageFiles.map((file, index) => (
							<div key={index} className={classes.thumbnailContainer}>
								<a
									href="#"
									onClick={(e) => {
										e.preventDefault();
										openImageModal(file);
									}}
								>
									{file && (
										<img
											src={typeof file === 'string' ? file : URL.createObjectURL(file)}
											className={classes.thumbnailImage}
											alt="Uploaded Thumbnail"
											onLoad={() => setImageLoaded(true)}
											// onError={(e) => retryImageLoading(e)}
										/>
									)}
									<br />
									<span className={`small--secondary`}>
										{typeof file === 'string' ? parseImageUrl(file) : file.name}
									</span>
								</a>
								{imageLoaded && (
									<ClearIcon
										fontSize={'small'}
										onClick={onRemove}
										className={`${classes.closingIcon} ${classes.thumbnailClosingIcon}`}
									/>
								)}
							</div>
						))}
					</Box>
				)}
			</Grid>
			{sampleImageUrl && (
				<Grid item sm={12} md={5} className={classes.imageUploadSample}>
					<img className={classes.exampleImage} src={sampleImageUrl} alt={sampleImageDesc} />
				</Grid>
			)}
		</Grid>
	);
};

export default ImageUpload;
