import React, { useState, useEffect, useImperativeHandle, useReducer } from 'react';
import { Box } from '@material-ui/core';
import ImageUpload from '../UI/QuestionTypes/ImageUpload';
import { useStyles } from './styles';
import TextQuestion from '../UI/QuestionTypes/TextQuestion';
import Modal from '../UI/Modal/Modal';
import { uploadImage, deleteImage, postAnswers } from '../../services/api';
import ClearIcon from '@material-ui/icons/Cancel';
import ToggleQuestion from '../UI/QuestionTypes/ToggleQuestion';
// import getConfig from 'next/config';

const Question27 = (props) => {
	const question = 'Do you have a second electrical panel?';
	const options = [
		{ value: '19', text: 'No' },
		{ value: '18', text: 'Yes' },
	];

	return (
		<ToggleQuestion
			name="question27"
			value={props.question27}
			onChange={props.handleChange}
			question={question}
			options={options}
		/>
	);
};

const IMAGE_ACTIONS = {
	SAVE_IMAGE: 'save_image',
	DELETE_IMAGE: 'delete_image',
	SET_LOADING: 'set_loading',
	SET_ERROR: 'set_error',
};

const imagesReducer = (images, action) => {
	switch (action.type) {
		case IMAGE_ACTIONS.SAVE_IMAGE:
			return images.map((image) => {
				if ('question' + image.questionId === action.payload.questionId) {
					return { ...image, imageFiles: action.payload.addedFiles, formData: action.payload.filename };
				} else {
					return image;
				}
			});
		case IMAGE_ACTIONS.DELETE_IMAGE:
			return images.map((image) => {
				if ('question' + image.questionId === action.payload.questionId) {
					return { ...image, imageFiles: [], formData: '' };
				} else {
					return image;
				}
			});
		case IMAGE_ACTIONS.SET_LOADING:
			return images.map((image) => {
				if ('question' + image.questionId === action.payload.questionId) {
					return { ...image, loading: action.payload.loading };
				} else {
					return image;
				}
			});
		case IMAGE_ACTIONS.SET_ERROR:
			return images.map((image) => {
				if ('question' + image.questionId === action.payload.questionId) {
					return { ...image, error: action.payload.error, removeError: action.payload.removeError };
				} else {
					return image;
				}
			});
		default:
			return images;
	}
};

const Group8 = (props) => {
	const classes = useStyles();
	// const { publicRuntimeConfig } = getConfig();
	// const publicRuntimeConfig = { basePath: '/electric' };
	const { submitRef, enableNavButton } = props;

	// ORDER MATTERS --- LIST IMAGES IN THE ORDER DISPLAYED
	const initialImages = [
		{
			questionId: 22,
			questionText:
				'Please take a photo of the entire area where you would like charging installed. Step back about 10 to 20 feet from preferred installation spot.',
			imageFiles: props.images && props.images['question22'] ? [props.images['question22']] : [],
			bottomText: '',
			filename: props.requestId + '_47',
			mandatory: true,
			loading: false,
			error: false,
			removeError: false,
			formData: props.formData.question22,
			sampleImgURL: `${props.imagePath}/installLocation.jpg`,
			sampleImgDesc: 'installation location',
		},
		{
			questionId: 23,
			questionText:
				'Please take a photo of your main electrical panel from 15-20 feet back to show its location.',
			imageFiles: props.images && props.images['question23'] ? [props.images['question23']] : [],
			bottomText: '',
			filename: props.requestId + '_48',
			mandatory: true,
			loading: false,
			error: false,
			removeError: false,
			formData: props.formData.question23,
			sampleImgURL: `${props.imagePath}/electricalPanelLocation.jpg`,
			sampleImgDesc: 'electrical panel location',
		},
		{
			questionId: 24,
			questionText:
				'Now, open the panel door and take a picture of your electrical panel. Make sure the photo is not blurry, so we can read the labels on the panel. Please capture the whole panel so the installer can see the main breaker and all the panel slots.',
			imageFiles: props.images && props.images['question24'] ? [props.images['question24']] : [],
			bottomText: '',
			filename: props.requestId + '_49',
			mandatory: true,
			loading: false,
			error: false,
			removeError: false,
			formData: props.formData.question24,
			sampleImgURL: `${props.imagePath}/electricalPanelSample.jpg`,
			sampleImgDesc: 'electrical panel interior',
		},
		{
			questionId: 42,
			questionText:
				'Please locate the round electrical meter, likely located on the side of your home. Take a photo of it by standing back far enough that it also captures the ground and the roof line.',
			imageFiles: props.images && props.images['question42'] ? [props.images['question42']] : [],
			bottomText: '',
			filename: props.requestId + '_125',
			mandatory: true,
			loading: false,
			error: false,
			removeError: false,
			formData: props.formData.question42,
			sampleImgURL: `${props.imagePath}/electricalMeterSample.jpg`,
			sampleImgDesc: 'electrical meter',
		},
		{
			questionId: 25,
			questionText:
				'If you have a second electrical panel, take or upload a picture of your second electrical panel from about 15-20 feet back to show its location.',
			imageFiles: props.images && props.images['question25'] ? [props.images['question25']] : [],
			bottomText: '',
			filename: props.requestId + '_72',
			mandatory: false,
			loading: false,
			error: false,
			removeError: false,
			formData: props.formData.question25,
		},
		{
			questionId: 43,
			questionText:
				'Now, open the sub-panel door and take a picture of the inside of your sub-electrical panel. Make sure the photo is not blurry, so we can read the labels on the panel. Please capture the whole panel so the installer can see the main breaker and all the panel slots.',
			imageFiles: props.images && props.images['question43'] ? [props.images['question43']] : [],
			bottomText: '',
			filename: props.requestId + '_126',
			mandatory: false,
			loading: false,
			error: false,
			removeError: false,
			formData: props.formData.question43,
		},
	];

	const [images, imagesDispatch] = useReducer(imagesReducer, initialImages);
	
	// Set toggle to Yes if any of the optional images were already uploaded
	let mandatoryPhotoIds = [];
	let optionalPhotoIds = [];

	for (let i = 0; i < images.length; i++) {
		if (images[i].mandatory) {
			mandatoryPhotoIds.push(images[i].questionId);
		} else {
			optionalPhotoIds.push(images[i].questionId);
		}
	}

	let openOptionalPhotosSection = false;
	for (let i = 0; i < optionalPhotoIds.length; i++) {
		if (props.images && props.images['question' + optionalPhotoIds[i]]) {
			openOptionalPhotosSection = true;
		}
	}

	const [requiredImagesCount, setRequiredImagesCount] = useState(mandatoryPhotoIds.length);
	const [requiredImagesUploadedCount, setRequiredImagesUploadedCount] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [imageUrl, setImageUrl] = useState('');

	const [question27, setQuestion27] = useState(openOptionalPhotosSection ? '18' : props.formData.question27);
	const [question16, setQuestion16] = useState(props.formData.question16);

	const checkImageUploaded = () => {
		let mandatoryPhotosSubmitted = 0;
		for (let i = 0; i < mandatoryPhotoIds.length; i++) {
			for (let j = 0; j < images.length; j++) {
				if (images[j].questionId === mandatoryPhotoIds[i] && images[j].imageFiles.length) {
					mandatoryPhotosSubmitted++;
				}
			}
		}

		setRequiredImagesUploadedCount(mandatoryPhotosSubmitted);
		enableNavButton(mandatoryPhotosSubmitted >= mandatoryPhotoIds.length);
	};

	useEffect(() => {
		checkImageUploaded();
	}, [images]);

	useImperativeHandle(submitRef, () => ({
		validateGroup8Form: () => {
			// add validation if needed
			return [
				true,
				{
					question16: question16,
					question22: images.find(({ questionId }) => questionId === 22)?.formData,
					question23: images.find(({ questionId }) => questionId === 23)?.formData,
					question24: images.find(({ questionId }) => questionId === 24)?.formData,
					question25: images.find(({ questionId }) => questionId === 25)?.formData,
					question27: images.find(({ questionId }) => questionId === 27)?.formData,
					question42: images.find(({ questionId }) => questionId === 42)?.formData,
					question43: images.find(({ questionId }) => questionId === 43)?.formData,
				},
			];
		},
	}));

	const handleQuestion16Change = (newValue) => {
		setQuestion16(newValue);
	};

	const handleQuestion27Change = (newValue) => {
		setQuestion27(newValue);
	};

	const openImageModal = (file) => {
		setImageUrl(typeof file === 'string' ? file : URL.createObjectURL(file));

		if (!showModal) {
			setShowModal(true);
		}
	};

	const onCloseModal = () => {
		if (showModal) {
			setShowModal(false);
		}
	};

	const onSave = async (questionId, addedFiles, filename) => {
		imagesDispatch({
			type: IMAGE_ACTIONS.SET_ERROR,
			payload: { questionId: 'question' + questionId, error: false, removeError: false },
		});
		if (addedFiles.length > 0 && typeof addedFiles[0] !== 'string') {
			imagesDispatch({
				type: IMAGE_ACTIONS.SET_LOADING,
				payload: { questionId: 'question' + questionId, loading: true },
			});
			const res = await uploadImage(props.apis, addedFiles[0], filename, props.backendBaseUrl);
			if (res.data) {
				imagesDispatch({
					type: IMAGE_ACTIONS.SAVE_IMAGE,
					payload: { questionId: 'question' + questionId, addedFiles: addedFiles, filename: res.data.data.imageUrl },
				});
				let newFormImages = { ...props.images, ['question' + questionId]: addedFiles[0] };
				props.setFormImages(newFormImages);
				let body = {};
				body['question' + questionId] = res.data.data.imageUrl;
				const answers = await postAnswers(body, props.apis, props.requestId, props.backendBaseUrl);
				if (!answers.data) {
					imagesDispatch({
						type: IMAGE_ACTIONS.SET_ERROR,
						payload: { questionId: 'question' + questionId, error: true },
					});
				}
			} else {
				imagesDispatch({
					type: IMAGE_ACTIONS.SET_ERROR,
					payload: { questionId: 'question' + questionId, error: true },
				});
				console.log('Error saving image: ', res.error);
			}
			imagesDispatch({
				type: IMAGE_ACTIONS.SET_LOADING,
				payload: { questionId: 'question' + questionId, loading: false },
			});
		}
	};

	const onRemove = async (questionId, filename) => {
		imagesDispatch({
			type: IMAGE_ACTIONS.SET_ERROR,
			payload: { questionId: 'question' + questionId, error: false, removeError: false },
		});
		imagesDispatch({
			type: IMAGE_ACTIONS.SET_LOADING,
			payload: { questionId: 'question' + questionId, loading: true },
		});
		const res = await deleteImage(props.apis, filename, props.backendBaseUrl);
		if (res.data) {
			imagesDispatch({ type: IMAGE_ACTIONS.DELETE_IMAGE, payload: { questionId: 'question' + questionId } });
			let newFormImages = { ...props.images, ['question' + questionId]: '' };
			props.setFormImages(newFormImages);
			let body = {};
			body['question' + questionId] = '';
			const answers = await postAnswers(body, props.apis, props.requestId, props.backendBaseUrl);
			if (!answers.data) {
				imagesDispatch({
					type: IMAGE_ACTIONS.SET_ERROR,
					payload: { questionId: 'question' + questionId, removeError: true },
				});
			}
		} else {
			imagesDispatch({
				type: IMAGE_ACTIONS.SET_ERROR,
				payload: { questionId: 'question' + questionId, removeError: true },
			});
			console.log('Error deleting image: ', res.error);
		}
		imagesDispatch({
			type: IMAGE_ACTIONS.SET_LOADING,
			payload: { questionId: 'question' + questionId, loading: false },
		});
	};

	const additionalInfoText = 'Is there anything else you think your installer should know?';

	return (
		<>
			{images.map((image) => (
				<div key={image.questionId}>
					{image.mandatory && (
						<Box mt={3}>
							<ImageUpload
								text={image.questionText}
								bottomText={image.bottomText}
								onSave={(addedFiles) => {
									onSave(image.questionId, addedFiles, image.filename);
								}}
								imageFiles={image.imageFiles}
								openImageModal={openImageModal}
								onRemove={() => {
									onRemove(image.questionId, image.filename);
								}}
								sampleImageUrl={image.sampleImgURL}
								sampleImageDesc={image.sampleImgDesc}
								imageLoading={image.loading}
								imageError={image.error}
								imageRemoveError={image.removeError}
							/>
						</Box>
					)}
				</div>
			))}
			<Question27 question27={question27} handleChange={handleQuestion27Change} />
			{question27 && question27 === '18' && (
				<>
					{images.map((image) => (
						<div key={image.questionId}>
							{!image.mandatory && (
								<Box mt={3}>
									<ImageUpload
										text={image.questionText}
										bottomText={image.bottomText}
										onSave={(addedFiles) => {
											onSave(image.questionId, addedFiles, image.filename);
										}}
										imageFiles={image.imageFiles}
										openImageModal={openImageModal}
										onRemove={() => {
											onRemove(image.questionId, image.filename);
										}}
										sampleImageUrl={image.sampleImgURL}
										sampleImageDesc={image.sampleImgDesc}
										imageLoading={image.loading}
										imageError={image.error}
									/>
								</Box>
							)}
						</div>
					))}
				</>
			)}
			<Box mt={3} className={classes.additionalInfoBox}>
				<form autoComplete="off">
					<TextQuestion
						name="question16"
						value={props.formData.question16 || ''}
						onChange={handleQuestion16Change}
						question={additionalInfoText}
						fullWidth={true}
						required={false}
						maxLength={255}
						multiline={true}
					/>
				</form>
			</Box>

			{`${requiredImagesUploadedCount}/${requiredImagesCount} required images uploaded`}
			<Modal show={showModal} hideModal={onCloseModal} classes={classes.modalRoot}>
				<div>
					<ClearIcon fontSize={'small'} className={classes.closingIcon} onClick={onCloseModal} />
					{imageUrl && <img src={imageUrl} className={classes.modalImage} alt="Uploaded Picture" />}
				</div>
			</Modal>
		</>
	);
};

export default Group8;
