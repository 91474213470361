import axios from "axios";
import resolve from "./resolve.js";
import { msalInstance } from "../../index";
import { loginRequest } from "../../authConfig";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { requestStatusMap } from "./appConfig";

const environments = {
  dev: "dev",
  qa: "qa",
  uat: "uat",
  perf: "perf",
  prodExt: "www.gmhomecharging.com",
  prod: "prod",
  akamai: "www-gmhomecharging-com",
};

export const getEndpointUrls = () => {
  const host = window.location.host;
  let endpointUrls = {};

  switch (true) {
    case host.includes("localhost"):
      endpointUrls["backendURL"] = "http://localhost:8080/proxy";
      //"https://apigw-ocnp.ext.gm.com/digital-ev-commerce/ext-dev/eviq-dev";
      endpointUrls["scheduleURL"] =
        "https://apigw-ocnp.ext.gm.com/digital-ev-commerce/ext-dev/eviq-schedule-dev";
      break;
    case host.includes(environments.dev):
      endpointUrls["backendURL"] = "/proxy";
      //"https://apigw-ocnp.ext.gm.com/digital-ev-commerce/ext-dev/eviq-dev";
      endpointUrls["scheduleURL"] =
        "https://apigw-ocnp.ext.gm.com/digital-ev-commerce/ext-dev/eviq-schedule-dev";
      break;
    case host.includes(environments.qa):
      endpointUrls["backendURL"] =
        "https://apigw-ocnp.ext.gm.com/digital-ev-commerce/ext-dev/eviq-qa";
      endpointUrls["scheduleURL"] =
        "https://apigw-ocnp.ext.gm.com/digital-ev-commerce/ext-dev/eviq-schedule-qa";
      break;
    case host.includes(environments.uat):
      endpointUrls["backendURL"] =
        "https://apigw-ocnp.ext.gm.com:443/digital-ev-commerce/ext-uat";
      endpointUrls["scheduleURL"] =
        "https://apigw-ocnp.ext.gm.com:443/digital-ev-commerce/ext-dev/eviq-schedule-api-uat";
      break;
    case host.includes(environments.perf):
      endpointUrls["backendURL"] =
        "https://apigw-ocnp.ext.gm.com/digital-ev-commerce/ext-dev/eviq-perf";
      endpointUrls["scheduleURL"] =
        "https://apigw-ocnp.ext.gm.com/digital-ev-commerce/ext-dev/eviq-schedule-perf";
      break;
    case host.includes(environments.prod) ||
      host.toLowerCase().includes(environments.prodExt) ||
      host.toLowerCase().includes(environments.akamai):
      endpointUrls["backendURL"] = "/proxy";
      endpointUrls["scheduleURL"] =
        "https://apigw-oc.ext.gm.com/gmap/ext-services/eviq-schedule-prod";
      break;
    default:
      endpointUrls["backendURL"] =
        "https://home-charging-customer-dev.op-epg14mi.gm.com/proxy";
      endpointUrls["scheduleURL"] =
        "https://apigw-ocnp.ext.gm.com/digital-ev-commerce/ext-dev/eviq-schedule-dev";
      break;
  }

  return endpointUrls;
};

const endpointUrls = getEndpointUrls();

/**
 * Used to get the user profile's idToken. If the idToken is expired, acquireTokenSilent refreshes the token using the refresh token.
 * If the refresh token is expired, user is redirected to Log In page.
 * @param {*} none
 * @returns String
 */
export const getIdToken = async () => {
  const account = msalInstance.getActiveAccount();
  if (!account) {
    throw Error("No active account!");
  }

  const tokenRequest = {
    ...loginRequest,
    account: account,
  };

  try {
    const response = await msalInstance.acquireTokenSilent(tokenRequest);
    return response.idToken;
  } catch (error) {
    if (error && error instanceof InteractionRequiredAuthError) {
      msalInstance.acquireTokenRedirect(tokenRequest);
    }
  }
};

/**
 * GET /install​/requests
 * Used to GET the install request info tied to a specific requestId
 * @param {*} requestId
 * @returns Array of Objects
 */
export const getRequest = async (requestId) => {
  const cachedRequestsData = JSON.parse(sessionStorage.getItem("gm.requests"));

  if (cachedRequestsData && cachedRequestsData[requestId]) {
    return cachedRequestsData[requestId];
  }

  return await axios
    .get(`${endpointUrls.backendURL}/install/requests`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        requestId: requestId,
      },
    })
    .then((res) => {
      return res.data[0];
    });
};

// Get request information using sessionID
export const getRequestId = async (sessionId) => {
  const cachedRequestsData = JSON.parse(sessionStorage.getItem("gm.requests"));

  if (cachedRequestsData && cachedRequestsData[sessionId]) {
    return cachedRequestsData[sessionId];
  }

  return await axios
    .get(`${endpointUrls.backendURL}/install/requests`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        sessionId: sessionId,
      },
    })
    .then((res) => {
      return res.data[0];
    });
};

//Cancel & Recreate Request as EVgo
export const recreateToEVGo = async (requestId) => {
  return await axios
    .post(
      `${endpointUrls.backendURL}/install/requests/${requestId}/switchToEVGo`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          requestId: requestId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

//Customer Decline/Accept Terms
export const consent = async (requestId, isAccepted) => {
  return await axios
    .post(
      `${endpointUrls.backendURL}/install/requests/${requestId}/consentaccept/${isAccepted}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          requestId: requestId,
        },
      }
    )
    .then((res) => {
      return res;
    });
};

/**
 * GET /snapshot/read/{snapshotId}
 * Used to GET information tied to a customer's snapshotId
 * @param {*} snapshotId
 * @returns Object
 */
export const getSnapshot = async (snapshotId) => {
  const idToken = await getIdToken();

  const cachedRequestsData = JSON.parse(sessionStorage.getItem("gm.requests"));
  if (cachedRequestsData) {
    const cachedRequestIds = Object.keys(cachedRequestsData);

    for (let i = 0; i < cachedRequestIds.length; i++) {
      if (
        cachedRequestsData &&
        cachedRequestsData[cachedRequestIds[i]]["snapshotId"] === snapshotId &&
        cachedRequestsData[cachedRequestIds[i]]["snapshot"]
      ) {
        return cachedRequestsData[cachedRequestIds[i]]["snapshot"];
      }
    }
  }

  return await axios
    .get(`${endpointUrls.backendURL}/snapshot/read/${snapshotId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
        tenant: "b2c",
      },
    })
    .then((res) => {
      let snapshotObj = {
        cust_snap_guid: res.data["cust_snap_guid"],
        first_name: res.data["person"]["first_name"],
        last_name: res.data["person"]["last_name"],
        email_address: res.data["emails"][0]["email_address"],
        street_address: res.data["addresses"][0]["address_line_1"],
        street_address2: res.data["addresses"][0]["address_line_2"],
        city_name: res.data["addresses"][0]["city_name"],
        postal_code: res.data["addresses"][0]["postal_code"],
        state_or_province: res.data["addresses"][0]["state_or_province"],
        address_country_code: res.data["addresses"][0]["country_code"],
        phone_number: res.data["phones"][0]["phone_number"],
      };

      return snapshotObj;
    });
};

/**
 * GET ​/questions​/responses​/strings
 * Search for customer responses to questions. Return customer responses associated with requestId.
 * @param {*} requestId
 * @returns Object
 */
export const getQuestionRequest = async (requestId) => {
  return await resolve(
    axios
      .get(`${endpointUrls.backendURL}/questions/responses/v2/strings`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          requestId: requestId,
        },
      })
      .then((res) => {
        return res.data;
      })
  );
};

/**
 * GET /api​/schedule
 * Search for schedule slots by availbility. Return all available schedule slots.
 * @returns Object
 */
export const getAppointmentSlots = async (zipCode, electricianId) => {
  return await resolve(
    axios
      .get(`${endpointUrls.scheduleURL}/api/schedule`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          available: true,
          zip: zipCode,
          electricianId: electricianId,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      })
  );
};

export const getBookedSlots = async (requestId) => {
  return await resolve(
    axios
      .get(`${endpointUrls.scheduleURL}/api/schedule`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          requestId: requestId,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      })
  );
};

/**
 * GET /api​/schedule
 * Search for schedule slots by availbility and appointment time. Return all available schedule slots by appointment time.
 * @param {*} startTime
 * @param {*} endTime
 * @returns Object
 */
export const getTimeSlots = async (
  startTime,
  endTime,
  zipCode,
  electricianId
) => {
  return await resolve(
    axios
      .get(`${endpointUrls.scheduleURL}/api/schedule`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          available: true,
          startTime: startTime,
          endTime: endTime,
          zip: zipCode,
          electricianId: electricianId,
        },
      })
      .then((res) => {
        return res;
      })
      .catch((error) => {
        return error;
      })
  );
};

/**
 * PATCH /api​/schedule
 * Search for schedule slot by scheduleId. Replace installRequestId in an available slot with the customer's requestId.
 * @param {*} scheduleId
 * @param {*} requestId
 * @returns Object
 */
export const makeAppointment = async (scheduleId, requestId) => {
  let payload = [
    {
      op: "replace",
      path: "/installRequestId",
      value: requestId,
    },
  ];

  return await axios
    .patch(
      `${endpointUrls.scheduleURL}/api/schedule/${scheduleId}`,

      payload,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const cancelAppointment = async (scheduleId) => {
  let payload = [
    {
      op: "replace",
      path: "/installRequestId",
      value: null,
    },
  ];

  return await axios
    .patch(
      `${endpointUrls.scheduleURL}/api/schedule/${scheduleId}`,

      payload,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

export const getElectrician = async (electricianId) => {
  return await axios
    .get(`${endpointUrls.scheduleURL}/api/electrician/${electricianId}`)
    .then((res) => {
      return res.data;
    });
};

/**
 * Used to GET all of the Request ID's associated to the Profile ID.
 * For each Request, the Snapshot data is also retrieved and stored inside the response object.
 * @param {*} profileId
 * @returns Object
 */
export const getRequestIds = async (profileID, forceCacheUpdate) => {
  const cachedRequestsData = JSON.parse(sessionStorage.getItem("gm.requests"));

  if (!cachedRequestsData || forceCacheUpdate) {
    return await axios
      .get(`${endpointUrls.backendURL}/install/requests`, {
        headers: {
          "Content-Type": "application/json",
        },
        params: {
          profileId: profileID,
        },
      })
      .then((res) => retrieveSnapshotBulk(res.data))
      .catch((error) => {
        return error;
      });
  } else {
    return cachedRequestsData;
  }
};

export const retrieveSnapshotBulk = async (requestsArray) => {
  const idToken = await getIdToken();

  const body = {
    list_of_cust_snap_guids: [],
  };

  const requestsObj = {};

  for (let i = 0; i < requestsArray.length; i++) {
    body["list_of_cust_snap_guids"].push(requestsArray[i]["snapshotId"]);
    requestsObj[requestsArray[i].requestId] = requestsArray[i];
  }

  return await axios
    .post(`${endpointUrls.backendURL}/snapshot/read/bulk`, body, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${idToken}`,
        tenant: "b2c",
      },
    })
    .then((res) => {
      const requestIds = Object.keys(requestsObj);
      for (let i = 0; i < res.data["css_bulk_search_response"].length; i++) {
        for (let j = 0; j < requestIds.length; j++) {
          if (
            requestsObj[requestIds[j]]["snapshotId"] ===
            res.data["css_bulk_search_response"][i]["cust_snap_guid"]
          ) {
            requestsObj[requestIds[j]]["snapshot"] =
              res.data["css_bulk_search_response"][i];
          }
        }
      }

      sessionStorage.setItem("gm.requests", JSON.stringify(requestsObj));

      return requestsObj;
    })
    .catch((error) => {
      return error;
    });
};

export const patchRequest = async (sessionId, profileId) => {
  const requestId = await axios
    .get(`${endpointUrls.backendURL}/install/requests`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        sessionId: sessionId,
      },
    })
    .then((res) => {
      return res.data[0].requestId;
    })
    .catch((err) => {});

  let payload = {
    profileId: profileId,
    modifiedBy: "System",
  };

  return await axios
    .patch(
      `${endpointUrls.backendURL}/install/requests/${requestId}`,

      payload,

      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * Used to get the status and schedule button function based on the Status Id.
 * The returned object is in the form of {status: <Active, Pending, Completed, Canceled>, scheduleButton: <schedule, view, disabled>}
 * @param {*} statusId
 * @returns Object
 */
export const getRequestStatus = (statusId) => {
  return requestStatusMap[statusId];
};

/**
 * Used to Update one Request ID in the cache.
 * The Snapshot data is preserved, only the response from GET /install/requests is updated.
 * @param {*} requestId
 * @returns Object
 */
export const updateRequest = async (requestId) => {
  const cachedRequestsData = JSON.parse(sessionStorage.getItem("gm.requests"));

  const response = await axios
    .get(`${endpointUrls.backendURL}/install/requests`, {
      headers: {
        "Content-Type": "application/json",
      },
      params: {
        requestId: requestId,
      },
    })
    .then((res) => {
      return res.data[0];
    });

  if (cachedRequestsData && cachedRequestsData[requestId]) {
    response["snapshot"] = cachedRequestsData[requestId].snapshot;
  } else {
    const snapshotData = await getSnapshot(response.snapshotId);
    response["snapshot"] = snapshotData;
  }

  cachedRequestsData[requestId] = response;

  sessionStorage.setItem("gm.requests", JSON.stringify(cachedRequestsData));

  return cachedRequestsData[requestId];
};
